// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}
// mini-sab home
.mini-sab-home-section {
	margin: 6vh 0;
	.mini-sab-home {
		@extend .mini-sab;
		padding: 6vh 2rem;
		background: $purple-light;
		border-radius: 30px 0px;
	}
	@include media-breakpoint-down(md) {
		margin: 2vh auto;
		.container {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}
// usp intro section
.usp-intro-section {
	margin: 9vh 0;
	.title h5 {
		text-transform: uppercase;
		font-family: $font-family-primary !important;
		font-weight: 200;
		font-size: 18px !important;
	}
	.description {
		font-size: 13px;
		max-width: 80%;
	}
	@include media-breakpoint-down(md) {
		margin: 4vh 0;
		.description {
			max-width: 100%;
		}
	}
}

// accommodaties sectie
.accommodation-section {
	margin: 6vh 0;
	.title {
		font-size: 60px;
	}
	.subtitle {
		font-size: 36px;
	}
	.acco-grid {
		margin: 9vh auto;
		.grid-items {
			.item {
				max-width: 50% !important;
				flex-basis: 50% !important;
				.card {
					border-radius: 60px 0 60px 0;
					border: none;
					box-shadow: none;
					.card-body {
						background: $gray;
						padding: 0.75rem 2rem 2rem 2rem;
					}
					.card-title {
						font-weight: 600;
						font-size: 36px;
						&:hover {
							color: $gray-dark;
						}
					}
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.title {
			font-size: 40px;
		}
		.subtitle {
			font-size: 24px;
		}
		.wysiwyg {
			margin-top: 0.5rem;
		}
		.acco-grid {
			margin: 3vh auto;

			.grid-items {
				.item {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}
			}
		}
	}
}

// eyecatcher-content-section
.content-eyecatcher-section {
	margin: 6vh auto;
}

.surroundings-section {
	margin: 6vh 0;
	overflow: hidden;
	.intro-container {
		margin: 1rem auto;
		.title {
			font-size: 60px;
		}
		.subtitle {
			font-size: 36px;
			margin-top: -0.5rem;
		}
		.description {
			max-width: 60%;
		}
	}
	.slider-container {
		margin-left: auto;
		max-width: unset !important;

		.owl-carousel {
			.owl-nav {
				display: none;
			}
			.owl-dots {
				max-width: max-content;
				margin: auto;
				margin-top: 1.5rem;

				button.owl-dot {
					span {
						background: $white;
						border-radius: 1px;
						width: 40px;
						max-height: 8px;
					}

					&.active span,
					&:hover span {
						background: $yellow !important;
						border-color: $yellow;
					}
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.title {
			font-size: 40px !important;
		}

		.subtitle {
			font-size: 24px !important;
			margin-top: -0.5rem;
		}

		.description {
			max-width: 100% !important;
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;
	.info {
		display: flex;
		flex-flow: column;
		.title {
			order: -1;
			> * {
				font-size: 60px;
			}
		}
		.subtitle > * {
			font-size: 36px;
		}
	}
	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
	.tommy{
		background: $purple-light;
		padding:1.5rem;
		border-radius: 30px 0;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
