// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	border-radius: 0 0 100px 0;
	.owl-caption-holder {
		height: 90%;

		display: flex;
		align-items: flex-end;
		justify-content: flex-start;

		.owl-title {
			text-align: start;
			font-weight: 700;
			font-size: 80px;
		}

		.owl-subtitle,
		.owl-description,
		.owl-btn {
			display: none !important;
		}
	}
	@include media-breakpoint-down(md) {
		.owl-dots,
		.owl-nav {
			display: none !important;
		}

		.owl-title {
			font-size: 40px !important;
		}
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 55vh;
	min-height: 540px;
	max-height: 1080px;
	border-radius: 0 0 100px 0;
	@include media-breakpoint-up(sm) {
		height: 75vh;
	}
}

.content-eyecatcher-section {
	overflow: hidden;
	.container-fluid {
		padding: 0 !important;
	}
	.owl-stage-outer {
		border-radius: 0 0 60px 0;
	}
	.owl-carousel .owl-item {
		height: 70vh;
		.owl-caption-holder {
			height: 100%;

			display: flex;
			align-items: flex-end;
			justify-content: flex-start;
			.owl-title {
				text-align: start;
				font-weight: 700;
				font-size: 80px;
			}
			.owl-subtitle,
			.owl-description,
			.owl-btn {
				display: none !important;
			}
		}
	}
	.owl-nav {
		display: none;
	}
	@include media-breakpoint-down(md) {
		.owl-dots,
		.owl-nav {
			display: none !important;
		}

		.owl-title {
			font-size: 40px !important;
		}
	}
}

// owl dots global
.owl-dots {
	button.owl-dot {
		span {
			background: $white;
			border-radius: 1px;
			width: 40px;
			min-width: 40px !important;
			max-height: 8px;
		}

		&.active span,
		&:hover span {
			background: $yellow !important;
			border-color: $yellow;
		}
	}
}
