// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

.container,
.container-fluid {
	@include media-breakpoint-down(md) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card,
.card {
	background: $purple-light;
	border-radius: 50px 0;
	border: none;
	box-shadow: none;
	.card-body {
		padding: 1.5rem 1.25rem;
	}
	.card-btn {
		background: transparent;
		padding: 0;
		border: 0;
		box-shadow: none;
		text-decoration: underline;
		position: relative;
		z-index: 1;
		overflow: hidden;
		&::before {
			content: "";
			display: block;
			background-color: $purple;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			bottom: 0;
			z-index: -1;
			opacity: 0.2;

			transform: translateY(-100%);
			transition: 0.35s ease;
		}
		&:hover {
			&::before {
				transform: translateY(0);
				top: 0;
				filter: blur(4px);
				transition: 0.35s ease;
			}
		}
	}
}
.gallery, .gallery-horizontal {
	background: $green-light;
	border-radius: 60px 0 60px 0;
	padding: 1.5rem 0.5rem 0.5rem 0.5rem;
	.gallery-item {
		img {

		}
		&.large-item, &.large-item img, &.large-item .gallery-link{
			border-radius: 60px 0 0 0;
		}
		&.last-item, &.last-item img, &.last-item .gallery-caption {
			border-radius: 0 0 60px 0;
		}
	}
}
