// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$yellow: #fdc700;
$purple: #4d5da9;
$gray-dark: #46565d;
$gray: #d9e1e0;
$purple-light: #dfe2f3;
$yellow-light: #f7ecc2;
$green-light: #d9e1e0;
$off-white: #f5f5f2;

$white: #fff;
$black: #000;

// theme-colors
$primary: $white;
$secondary: $gray-dark;

// body
$body-bg: $off-white;
$body-color: $black;

// links
$link-color: $secondary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Proza+Libre:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");
$font-family-primary: "Proza Libre", sans-serif;
$font-family-secondary: "Cormorant", serif;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2; // 32px
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-color: $black;

// contextual
$light: $yellow-light;
$dark: $gray-dark;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $secondary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
