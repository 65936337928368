// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
		.logo {
			width: 120px;
			transition: 0.35s ease;
		}
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
	}

	// logo
	.logo {
		width: 240px;
		margin: 25px auto;
		transition: 0.35s ease;
		transform: translateX(0);
		transition: 0.35s ease;
		a {
			@extend .d-block;

			img {
				@extend .w-100;
				border-radius: 0;
				transition: 0.35s ease;
			}
		}

		@include media-breakpoint-down(md) {
			a {
				display: flex !important;
			}

			order: 2;
			width: 100%;
			margin: 15px auto;
			transform: none;

			picture,
			img {
				max-width: 304px;
				margin: auto !important;
				justify-self: center;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(md) {
			order: 4;
			.navbar-nav {
				flex-flow: column;
				gap: 0rem;
				padding-left: 2rem;
				margin-left: initial;
				margin-top: 1rem;

				.nav-link {
					font-family: $font-family-secondary;
					font-size: 22px;
					font-weight: 700;
					display: flex;
					align-items: center;
					gap: 0.35rem;
					&.dropdown-toggle {
						&::after {
							display: initial !important;
							float: right;
						}
					}
				}
				.dropdown-menu.show {
					position: static !important;
					transform: none !important;
					box-shadow: none !important;
					border: none !important;
					background: none !important;

					&::after,
					&::before {
						display: none;
					}

					.nav-link {
						font-size: 14px;
						font-family: $font-family-primary;
						font-weight: 300;
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			.navbar-nav {
				flex-flow: row;
				gap: 0.35rem;
				margin-left: 1rem;
				.nav-item {
					position: relative;
					&::after {
						content: "";
						position: absolute;
						left: 50%;
						bottom: 2px;
						width: 0%;
						height: 1px;
						background: $secondary;
						transition: 0.35s ease;
						transform: translateX(-50%);
					}

					&:hover {
						&:after {
							width: 80%;
							transition: 0.35s ease;
						}
					}
				}
				.nav-link {
					font-family: $font-family-secondary;
					font-size: 22px;
					font-weight: 700;
				}

				.dropdown-menu.show {
					box-shadow: none !important;
					border: none !important;
					z-index: 1000;

					&::after,
					&::before {
						display: none;
					}

					.nav-link {
						font-size: 18px;
						font-family: $font-family-primary;
						font-weight: 300;
						&:hover {
							color: $secondary;
						}
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
	}

	// 	// book-button
	div.book-button {
		order: 2;
		margin-left: 30px;

		@include media-breakpoint-down(sm) {
			margin-left: 10px;
		}

		ul {
			li {
				a {
					@extend .btn, .btn-header;
					color: $white;
					line-height: 1.5 !important;

					@include media-breakpoint-down(sm) {
						padding: 0.375rem 0.75rem !important;
					}
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		margin-left: auto;

		&.sticky-mobile {
			position: fixed !important;
			padding-bottom: 0.5rem;
			.logo {
				display: none;
			}
		}
	}

	// header contact icons
	div.header-contact-icons {
		order: 2;
		ul {
			display: flex;
			gap: 1.2rem;
			align-items: center;

			li {
				.list-item-title {
					display: none;
				}

				i {
					color: $gray-dark;
					font-size: 20px;
				}

				&:hover {
					i {
						transform: scaleX(-1);
						transition: 0.35s ease;
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			margin-left: auto;
		}
	}
}
