// footer
.footer {
	background: $yellow-light;
	padding: 6vh 0 0 0;
	border-radius: 100px 0 0 0;
	.footer-intro-section {
		padding-bottom: 6vh;
		.footer-text {
			h1 {
				font-size: 60px;
			}
		}
		.footer-list {
			h5 {
				display: none;
			}
			a {
				font-family: $font-family-secondary;
				font-size: 20px;
				font-weight: 600;
				color: $black;
			}
		}
		@include media-breakpoint-down(md) {
			.footer-text {
				h1 {
					font-size: 40px !important;
				}
			}
		}
	}
	.footer-outro-section {
		border-radius: 100px 0 0 0;
		background: $gray-dark;
		color: $white;
		padding-top: 6vh;
		padding-bottom: 12vh;
		.footer-text {
			max-width: 60%;
			h1,
			h2,
			h3,
			h4,
			h5.h6 {
				color: $white;
			}
			i,
			em {
				font-weight: 900;
			}
			margin-bottom: 2rem;
		}
		div.social-media {
			ul.social-media {
				position: absolute;
				right: 0;
				top: 0;
				display: flex;
				gap: 0.75rem;
				li {
					.list-item-title {
						display: none;
					}
					i {
						color: $white;
						font-size: 24px;
						transition: 0.35s ease;
					}
					&:hover {
						i {
							transform: scale(1.1);
							transition: 0.35s ease;
						}
					}
				}
			}
		}
		.btn {
			margin-right: 0.5rem;
		}
		@include media-breakpoint-down(md) {
			padding-top: 12vh !important;

			.footer-text {
				max-width: 100%;
			}
			div.social-media {
				ul.social-media {
					top: -6vh;
					right: 1.75rem;
				}
			}
			.btn {
				margin-bottom: 0.5rem;
			}
		}
	}
	.footer-socket {
		padding: 15px 0;
		background: $gray-dark;
		a {
			color: $white;
			margin-right: 1rem;
			font-size: 14px;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		@include media-breakpoint-down(md) {
			.list {
				flex-flow: column;
				gap: 0.75rem;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.container,
		.container-fluid {
			padding-left: 1.75rem !important;
			padding-right: 1.75rem !important;
		}
	}
}
