.accommodation-overview.collection {
	&.grid {
		.grid-items {
			.item {
				@include media-breakpoint-up(lg) {
					@include make-col(3);
				}
			}
		}
	}
}
