// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin-bottom: 0.35rem;
		&::before {
			content: "\f111";
			font-family: $font-awesome;
			font-weight: 600;
			font-size: 12px;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		&::before {
			content: "\f058";
			font-family: $font-awesome;
			color: $primary;
			font-weight: 400;
		}
	}
}
